<template>
  <div>
    <NavBar :navSelIndex = "-1"></NavBar>
    <div class="k3"></div>
    <div class="a0">
      <div class="b0">
        <span class="c0">{{buildingDetail.buildName}}</span>
        <span class="c1">{{buildingDetail.measureMin}}-{{buildingDetail.measureMax}}㎡</span>
        <span class="c1" v-if="buildingDetail.houseType == 1">一房一厅</span>
        <span class="c1" v-else-if="buildingDetail.houseType == 2">两房一厅</span>
        <span class="c1" v-else-if="buildingDetail.houseType == 3">三房两厅</span>
        <span class="c1" v-else-if="buildingDetail.houseType == 4">四房两厅</span>
        <span class="c1" v-else-if="buildingDetail.houseType == 5">五房两厅</span>
        <span class="c1" v-else>五房以上</span>
      </div>
      <b-row class="b1">
        <b-col class="c0" sm="12" md="12" lg="12" xl="9">
          <img :src="buildingDetail.coverUrl" alt="">
        </b-col>
        <b-col class="c1" sm="12" md="12" lg="12" xl="3">
            <img @click="witchPicture(item)" v-for="(item,index) in buildingDetail.buildImg.split(',')" :key="index" :src="item" alt="">
        </b-col>
      </b-row>
    </div>
    <div class="a0">
      <div class="b01" v-html="buildingDetail.buildIntroduce">
      </div>
    </div>
    <div class="a0">
      <div class="r01">
        <span>全屋家居方案</span>
      </div>
      <div class="b0t" v-for="(item,index) in buildingDetail.householdList" :key="index">
        <b-row class="c0t">

        <b-col class="c0" sm="12" md="12" lg="12" xl="6">
          <img :src="item.imgUrl" alt="">
        </b-col>

        <b-col class="c1" sm="12" md="12" lg="12" xl="6">
          <img class="d0" :src="item.coverUrl" alt="">
          <div class="d1">
            <div class="e0">
              <div class="f0">
                <span class="g0">{{item.title}}</span>
                <span class="g1">{{item.remarks}}</span>
              </div>
              <span class="f1">[{{item.styleName || '暂无'}}]</span>
              <div class="f2">
                <img class="g0" v-if="item.setmealId" @click="goEr(item.setmealId)" src="../../../public/icon/erW.png" alt="">
                <!-- <img class="g1" v-if="item.threeDUrl" @click="goVr(item.threeDUrl)" src="../../../public/icon/sanW.png" alt=""> -->
              </div>
            </div>
            <!-- <div class="e1" v-if="item.threeDUrl">
              <img :src="'https://qrcode.jp/qr?q='+item.threeDUrl" alt="">
              <span>扫码体验720°VR场景</span>
            </div> -->
            <!-- <div class="e1" v-if="!item.threeDUrl && item.twoDId">
              <img :src="'https://qrcode.jp/qr?q=https://sjmeijia.cn/#/twoDimensionalProgramme?id='+item.twoDId" alt="">
              <span>扫码体验二维场景</span>
            </div> -->
          </div>
        </b-col>
         </b-row>
      </div>
    </div>
    <Right></Right>
    <Footer></Footer>
  </div>
</template>
<script>
import NavBar from "@/components/Navbar.vue";
import Right from "@/components/Right.vue";
import Footer from "@/components/Footer.vue";
export default {
  components:{
    NavBar,Right,Footer
  },
  data(){
    return{
      banners: [],
      buildingDetail:''
    }
  },
  mounted(){
    this.buildingDetails(this.$route.query.id)
    this.getBanners()
  },
  methods:{
    getBanners() {
      const that = this;
      that.$api
        .banners({
          banner_type: 1,
        })
        .then((res) => {
          that.banners = res.data;
        });
    },
    witchPicture(item){
      this.buildingDetail.coverUrl = item
    },
    goVr(url){
      window.open(url)
    },
    goEr(id){
      window.open('/#/packageDetails?setmeal_id='+id)
      // this.$router.push({path:'/packageDetails',query:{setmeal_id:id}})
    },
    buildingDetails(id){
      const that = this;
      that.$api.buildingDetail({id:id}).then((res) => {
        that.buildingDetail = res.data
      });
    },
  }
}
</script>
<style lang="scss" scoped>
@import "../../assets/scss/style.scss";
@media (max-width: 1200px){
  .a0{
    padding: 0 2rem !important;
  }
  .a0 .b1 .c1 img {
    width: 14rem !important;
    height: 10.0625rem;
    object-fit: cover;
  }
}
.row{
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
@media (max-width: 992px){
  .a0 .b1 .c1 img {
    width: 10rem !important;
    height: 7.1875rem !important;
  }
}
.row{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
body{
  background-color: #F2F2F2 !important;
}
@media (min-width: 1200px) {
  .a0{
    padding: 0 22.5rem;
    .b1{
    display: flex;
    justify-content: space-between;
    .c0{
      width: 57rem;
      height: 41.1875rem;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .c1{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // padding-left: 1.625rem;
      border-left: 1px solid #221815;
      align-items: flex-end;
      img{
        width: 16.5rem;
        height: 9.75rem;
        object-fit: cover;
      }
    }
  }
  }
}
@media (max-width: 1200px) {
  .a0{
     .b1{
    display: flex;
    justify-content: space-between;
    .c0{
      width: 57rem;
      height: auto;
      padding-left: 0;
      padding-right: 0;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .c1{
      display: flex;
      justify-content: space-between;
      border-left: none;
      padding-top: 1rem;
      padding-left: 0;
      padding-right: 0;
      img{
        width: 11rem;
        height: 10.0625rem;
        object-fit: cover;
      }
    }
  }
  }
}
.k3{
  padding: 5rem 0 0 0;
}
.a0{
  
  
  .b0{
    text-align: left;
    padding-bottom: .9375rem;
    .c0{
      font-size: 1.6875rem;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #221815;
    }
    .c1{
      font-size: 15px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #221815;
      padding-left: 1.625rem;
    }
  }
  
}
.b01{
  padding: 2.875rem 0 5.3125rem 0;
  text-align: left;
}
.r01{
  padding-bottom: 1.0625rem;
  border-bottom:.0625rem solid #C9CACA;
  span{
    font-size: 1.3125rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #221815;
  }
}
.b0t{
  display: flex;
  margin-top: 1.75rem;
  .c0t{
    width: 100%;
    margin-left: 0;
    padding-bottom: 1.6875rem;
  }
  .c0{
    padding: 0;
    img{
      width: 100%;
      height: 37.5rem;    
    }
  }
  .c1{
    padding: 2.5625rem 3.625rem 1.1875rem 3.625rem;
    background: #DCDDDD;
    .d0{
      width: 100%;
      height: 22.3125rem;
    }
    .d1{
      display: flex;
      justify-content: space-between;
      .e0{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .f0{
          margin-top: 2.5625rem;
          .g0{
            font-size: 1.6875rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #221815;
          }
          .g1{
            font-size: .9375rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #221815;
            margin-left: 1.25rem;
          }
        }
        .f1{
          font-size: .9375rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #221815;
          padding: 1.125rem 0 1.25rem 0;
        }
        .f2{
          display: flex;
          .g0{
             display: inline-block;
            width: 8.75rem;
            height: 2.25rem;
            color: #ffffff;
            text-align: center;
            line-height: 2rem;
            cursor: pointer;
          }
          .g1{
            display: inline-block;
            width: 8.75rem;
            height: 2.25rem;
            color: #ffffff;
            text-align: center;
            cursor: pointer;
            margin-left:1rem;
          }
         
        }
      }
      .e1{
        display: flex;
        flex-direction: column;
        img{
          width: 7.5rem;
          height: 7.5rem;
          margin: 1.75rem 0 .375rem 0;
        }
        span{
          font-size: .8125rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #221815;
        }
      }
    }
  }
}
</style>